import LogoLockup from "@components/LogoLockup/LogoLockup"
import React from "react"

import "./Footer.scss"

const Footer: React.FC = () => {
  return (
    <footer className="wrapper">
      <div className="footer">
        <div className="footer--logo">
          <a href="http://www.hagerty.com/">
            <LogoLockup text="Let's Drive Together" />
          </a>
        </div>
        <div className="footer--nav-group footer--secondary-nav footer--social-icons">
          <a href="https://www.facebook.com/Hagerty/" title="Facebook">
            <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyMyAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIzIDQuMDA5MzdDMjMgMS45OTUwOCAyMS4xODE1IDAuMTc1NzgxIDE5LjE2NjcgMC4xNzU3ODFIMy44MzMzM0MxLjgxODUzIDAuMTc1NzgxIDAgMS45OTUwOCAwIDQuMDA5MzdWMTkuMzQyMkMwIDIxLjM1NjUgMS44MTg1MyAyMy4xNzU4IDMuODMzNTkgMjMuMTc1OEgxMS41VjE0LjQ4NjlIOC42ODg4OVYxMC42NTM2SDExLjVWOS4xNjAwOUMxMS41IDYuNTg0MzUgMTMuNDM0IDQuMjY0NjcgMTUuODEyNSA0LjI2NDY3SDE4LjkxMTFWOC4wOThIMTUuODEyNUMxNS40NzM0IDguMDk4IDE1LjA3NzggOC41MDk3IDE1LjA3NzggOS4xMjYzNlYxMC42NTM2SDE4LjkxMTFWMTQuNDg2OUgxNS4wNzc4VjIzLjE3NThIMTkuMTY2N0MyMS4xODE1IDIzLjE3NTggMjMgMjEuMzU2NSAyMyAxOS4zNDIyVjQuMDA5MzdaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K" />
          </a>
          <a href="https://twitter.com/Hagerty" title="Twitter">
            <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyNCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI0LjAwMDEgMi4zOTcyNkMyMy4xMzk5IDIuNzc4NzIgMjIuMjIzNCAzLjAzMTU3IDIxLjI2NzggMy4xNTQzM0MyMi4yNTA4IDIuNTYwOTUgMjMuMDAxMSAxLjYyODQ5IDIzLjM1MzkgMC41MDQ1NjVDMjIuNDM3MyAxLjA1NzAzIDIxLjQyNTQgMS40NDcyNiAyMC4zNDY5IDEuNjY1MDNDMTkuNDc2NiAwLjcyODE4IDE4LjIzNjIgMC4xNDc5NDkgMTYuODgzMSAwLjE0Nzk0OUMxNC4yNTc4IDAuMTQ3OTQ5IDEyLjE0NDMgMi4zMDIyNiAxMi4xNDQzIDQuOTQzMjZDMTIuMTQ0MyA1LjMyMzI2IDEyLjE3NjEgNS42ODg2NCAxMi4yNTQxIDYuMDM2NDlDOC4zMTE4NSA1Ljg0MjEgNC44MjM0OSAzLjkzMTg3IDIuNDgwMDkgMS4wMjE5NUMyLjA3MDk3IDEuNzM5NTcgMS44MzA5OSAyLjU2MDk1IDEuODMwOTkgMy40NDUxOEMxLjgzMDk5IDUuMTA1NDkgMi42NzY2OSA2LjU3NzI2IDMuOTM3MyA3LjQyOTM0QzMuMTc1NDQgNy40MTQ3MiAyLjQyODA0IDcuMTkxMSAxLjc5NDg1IDYuODM4ODdDMS43OTQ4NSA2Ljg1MzQ5IDEuNzk0ODUgNi44NzI0OSAxLjc5NDg1IDYuODkxNDlDMS43OTQ4NSA5LjIyMTE4IDMuNDM4NTUgMTEuMTU2MyA1LjU5NDAyIDExLjYwMkM1LjIwODAzIDExLjcwODcgNC43ODczNSAxMS43NTk5IDQuMzUwNzYgMTEuNzU5OUM0LjA0NzE3IDExLjc1OTkgMy43NDA2OSAxMS43NDIzIDMuNDUzMDEgMTEuNjc4QzQuMDY3NDEgMTMuNTc2NiA1LjgxMDg3IDE0Ljk3MjMgNy44ODM5MyAxNS4wMTc2QzYuMjcwNTkgMTYuMjkzNiA0LjIyMjEgMTcuMDYyMyAyLjAwNDQ3IDE3LjA2MjNDMS42MTU1OSAxNy4wNjIzIDEuMjQyNjEgMTcuMDQ0OCAwLjg2OTYyOSAxNi45OTY2QzIuOTcwMTYgMTguMzY2IDUuNDU5NTcgMTkuMTQ4IDguMTQ0MTUgMTkuMTQ4QzE2Ljg3MDEgMTkuMTQ4IDIxLjY0MDggMTEuODQwMyAyMS42NDA4IDUuNTA1OTVDMjEuNjQwOCA1LjI5NDAzIDIxLjYzMzUgNS4wODk0MSAyMS42MjM0IDQuODg2MjZDMjIuNTY0NSA0LjIxMTAzIDIzLjM1NTMgMy4zNjc3MiAyNC4wMDAxIDIuMzk3MjZaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K" />
          </a>
          <a href="https://www.instagram.com/hagerty" title="Instagram">
            <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyMyAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjgxMjUgMC4xNzU3ODFINy4xODc1QzMuMjE4NTYgMC4xNzU3ODEgMCAzLjM5NDM0IDAgNy4zNjMyOFYxNS45ODgzQzAgMTkuOTU3MiAzLjIxODU2IDIzLjE3NTggNy4xODc1IDIzLjE3NThIMTUuODEyNUMxOS43ODE0IDIzLjE3NTggMjMgMTkuOTU3MiAyMyAxNS45ODgzVjcuMzYzMjhDMjMgMy4zOTQzNCAxOS43ODE0IDAuMTc1NzgxIDE1LjgxMjUgMC4xNzU3ODFaTTIwLjg0MzggMTUuOTg4M0MyMC44NDM4IDE4Ljc2MjcgMTguNTg2OSAyMS4wMTk1IDE1LjgxMjUgMjEuMDE5NUg3LjE4NzVDNC40MTMxMyAyMS4wMTk1IDIuMTU2MjUgMTguNzYyNyAyLjE1NjI1IDE1Ljk4ODNWNy4zNjMyOEMyLjE1NjI1IDQuNTg4OTEgNC40MTMxMyAyLjMzMjAzIDcuMTg3NSAyLjMzMjAzSDE1LjgxMjVDMTguNTg2OSAyLjMzMjAzIDIwLjg0MzggNC41ODg5MSAyMC44NDM4IDcuMzYzMjhWMTUuOTg4M1oiIGZpbGw9ImJsYWNrIi8+CjxwYXRoIGQ9Ik0xMS41IDUuOTI1NzhDOC4zMjQ1NiA1LjkyNTc4IDUuNzUgOC41MDAzNCA1Ljc1IDExLjY3NThDNS43NSAxNC44NTEyIDguMzI0NTYgMTcuNDI1OCAxMS41IDE3LjQyNThDMTQuNjc1NCAxNy40MjU4IDE3LjI1IDE0Ljg1MTIgMTcuMjUgMTEuNjc1OEMxNy4yNSA4LjUwMDM0IDE0LjY3NTQgNS45MjU3OCAxMS41IDUuOTI1NzhaTTExLjUgMTUuMjY5NUM5LjUxOTEyIDE1LjI2OTUgNy45MDYyNSAxMy42NTY3IDcuOTA2MjUgMTEuNjc1OEM3LjkwNjI1IDkuNjkzNDcgOS41MTkxMiA4LjA4MjAzIDExLjUgOC4wODIwM0MxMy40ODA5IDguMDgyMDMgMTUuMDkzOCA5LjY5MzQ3IDE1LjA5MzggMTEuNjc1OEMxNS4wOTM4IDEzLjY1NjcgMTMuNDgwOSAxNS4yNjk1IDExLjUgMTUuMjY5NVoiIGZpbGw9ImJsYWNrIi8+CjxwYXRoIGQ9Ik0xNy42ODIyIDYuMjYyODRDMTguMTA1NCA2LjI2Mjg0IDE4LjQ0ODQgNS45MTk4IDE4LjQ0ODQgNS40OTY2NUMxOC40NDg0IDUuMDczNSAxOC4xMDU0IDQuNzMwNDcgMTcuNjgyMiA0LjczMDQ3QzE3LjI1OSA0LjczMDQ3IDE2LjkxNiA1LjA3MzUgMTYuOTE2IDUuNDk2NjVDMTYuOTE2IDUuOTE5OCAxNy4yNTkgNi4yNjI4NCAxNy42ODIyIDYuMjYyODRaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCLgEVx4mzk3T3mzgbKG54Eg"
            title="YouTube"
          >
            <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAzMyAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI2LjE0NjMgMEg2Ljg1MzY4QzMuMDY4NDkgMCAwIDMuMDY4NDkgMCA2Ljg1MzY4VjE2LjQ5OEMwIDIwLjI4MzEgMy4wNjg0OSAyMy4zNTE2IDYuODUzNjggMjMuMzUxNkgyNi4xNDYzQzI5LjkzMTUgMjMuMzUxNiAzMyAyMC4yODMxIDMzIDE2LjQ5OFY2Ljg1MzY4QzMzIDMuMDY4NDkgMjkuOTMxNSAwIDI2LjE0NjMgMFpNMjEuNTExMiAxMi4xNDVMMTIuNDg3NCAxNi40NDg5QzEyLjI0NyAxNi41NjM1IDExLjk2OTIgMTYuMzg4MiAxMS45NjkyIDE2LjEyMTlWNy4yNDUyNEMxMS45NjkyIDYuOTc1MDggMTIuMjU0MyA2Ljc5OTk5IDEyLjQ5NTIgNi45MjIxMUwyMS41MTkgMTEuNDk0OUMyMS43ODczIDExLjYzMDkgMjEuNzgyNyAxMi4wMTU2IDIxLjUxMTIgMTIuMTQ1WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==" />
          </a>
        </div>
      </div>
      <div className="footer--nav-group footer--tertiary-nav">
        <a href="https://submit-irm.trustarc.com/services/validation/77672a09-1fe3-41a9-b911-46cc3603a305" title="California Consumer Privacy Act (CCPA) Opt-Out Icon">
          <img className="ccpa-icon" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAzMCAxNCIgc3R5bGU9Im1hcmdpbi1yaWdodDo1cHg7IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZmlsbD0iI2ZmZmZmZiIgZD0iTTcuNCwxMi44aDYuOGwzLjEtMTEuNkg3LjRDNC4yLDEuMiwxLjYsMy44LDEuNiw3UzQuMiwxMi44LDcuNCwxMi44eiI+PC9wYXRoPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBmaWxsPSIjMDA2NmZmIiBkPSJNMjIuNiwwSDcuNGMtMy45LDAtNywzLjEtNyw3czMuMSw3LDcsN2gxNS4yYzMuOSwwLDctMy4xLDctN1MyNi40LDAsMjIuNiwweiBNMS42LDdjMC0zLjIsMi42LTUuOCw1LjgtNS44aDkuOWwtMy4xLDExLjZINy40QzQuMiwxMi44LDEuNiwxMC4yLDEuNiw3eiI+PC9wYXRoPjxwYXRoIGZpbGw9IiNmZmZmZmYiIGQ9Ik0yNC42LDRjMC4yLDAuMiwwLjIsMC42LDAsMC44bDAsMEwyMi41LDdsMi4yLDIuMmMwLjIsMC4yLDAuMiwwLjYsMCwwLjhjLTAuMiwwLjItMC42LDAuMi0wLjgsMGwwLDBsLTIuMi0yLjJMMTkuNSwxMGMtMC4yLDAuMi0wLjYsMC4yLTAuOCwwYy0wLjItMC4yLTAuMi0wLjYsMC0wLjhsMCwwTDIwLjgsN2wtMi4yLTIuMmMtMC4yLTAuMi0wLjItMC42LDAtMC44YzAuMi0wLjIsMC42LTAuMiwwLjgsMGwwLDBsMi4yLDIuMkwyMy44LDRDMjQsMy44LDI0LjQsMy44LDI0LjYsNHoiPjwvcGF0aD48cGF0aCBmaWxsPSIjMDA2NmZmIiBkPSJNMTIuNyw0LjFjMC4yLDAuMiwwLjMsMC42LDAuMSwwLjhsMCwwTDguNiw5LjhDOC41LDkuOSw4LjQsMTAsOC4zLDEwYy0wLjIsMC4xLTAuNSwwLjEtMC43LTAuMWwwLDBMNS40LDcuN2MtMC4yLTAuMi0wLjItMC42LDAtMC44YzAuMi0wLjIsMC42LTAuMiwwLjgsMGwwLDBMOCw4LjZsMy44LTQuNUMxMiwzLjksMTIuNCwzLjksMTIuNyw0LjF6Ij48L3BhdGg+Cjwvc3ZnPgo=" />
          Your Privacy Choices
        </a>
        <a href="https://www.hagerty.com/corporate/hagerty-disclosures">
          Disclosures
        </a>
        <a href="https://www.hagerty.com/corporate/privacy-policy">Privacy</a>
        <a href="https://www.hagerty.com/corporate/legal">Terms of Use</a>
        <a href="mailto:garage@hagerty.com">Contact Us</a>
      </div>
    </footer>
  )
}

export default Footer
