import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Button from "../Button/Button"

import "./BannerCTA.scss"

interface IHeroImage {
  publicURL: string
}
interface IData {
  car1: IHeroImage
}

const BannerCTA: React.FC = () => {
  const data: IData = useStaticQuery(graphql`
    {
      car1: file(relativePath: { eq: "footer-contact.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <section className="wrapper banner-cta banner-cta--dark">
      <div className="banner-cta--image">
        <Img fluid={data.car1.childImageSharp.fluid} />
      </div>
      <div className="banner-cta--container">
        <div className="banner-cta--title">
          <h2 className="f-h2 f-h-lite">
            We're always learning something in the Garage
          </h2>
        </div>
        <div className="banner-cta--action">
          <p className="f-h3 f-h-lite">Reach out and say hello</p>
          <Button
            ctaLink="mailto:garage@hagerty.com"
            ctaDescription="Contact us"
            externalLink={true}
          />
        </div>
      </div>
    </section>
  )
}

export default BannerCTA
