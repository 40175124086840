import LogoLockup from "@components/LogoLockup/LogoLockup"
import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import "./NavBar.scss"

interface IMenuLink {
  link: string
  name: string
}

interface INavigationBuilder {
  site: {
    siteMetadata: {
      menuLinks: IMenuLink[]
    }
  }
}

interface INavBar {
  darkTheme: boolean
}

const Navbar: React.FC<INavBar> = props => {
  const data: INavigationBuilder = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          menuLinks {
            link
            name
          }
        }
      }
    }
  `)

  return (
    <div
      className={`${props.darkTheme ? "header--dark" : ""} 
    ${"wrapper header--float"}`}
    >
      <header className="header">
        <LogoLockup text="|&nbsp;&nbsp;Learning Garage&nbsp;" />
        <nav className="header-nav">
          {data.site.siteMetadata.menuLinks.map(
            (navData: IMenuLink, index: number) => (
              <Link
                key={index}
                to={navData.link}
                partiallyActive={navData.name === "Vehicles" ? true : false}
                className="header-nav__link"
                activeClassName="header-nav__link-active"
              >
                {navData.name}
              </Link>
            )
          )}
        </nav>
      </header>
    </div>
  )
}

export default Navbar
