import HagertyLogo from "@assets/logos/hagerty.svg"
import { Link } from "gatsby"
import React from "react"
import "./LogoLockup.scss"

interface ILogoLockup {
  text: string
}

const LogoLockup: React.FC<ILogoLockup> = props => {
  return (
    <div className="logo">
      <Link to="/" className="logo__text">
        <HagertyLogo />
        {props.text}
      </Link>
    </div>
  )
}

export default LogoLockup
