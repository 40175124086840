import { Link } from "gatsby"
import React from "react"
import "./Button.scss"

interface IButton {
  ctaLink: string
  ctaDescription: string
  externalLink: boolean
}

const Button: React.FC<IButton> = props => {
  return (
    <React.Fragment>
      {props.externalLink === true ? (
        <a href={props.ctaLink} className="button button--primary">
          {props.ctaDescription}
        </a>
      ) : (
        <Link to={props.ctaLink} className="button button--primary">
          {props.ctaDescription}
        </Link>
      )}
    </React.Fragment>
  )
}

Button.defaultProps = {
  externalLink: false,
}

export default Button
