import Footer from "@components/Footer/Footer"
import Navbar from "@components/NavBar/NavBar"
import React, { ReactChildren } from "react"
import { Helmet } from "react-helmet"

interface ILayout {
  metaPageTitle: string
  metaDescription?: string
  darkTheme: boolean
  children: ReactChildren
}

const Layout: React.FC<ILayout> = props => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{props.metaPageTitle}</title>
        {props.metaDescription && (
          <meta name="description" content={props.metaDescription} />
        )}
      </Helmet>
      <Navbar darkTheme={props.darkTheme} />
      <main>{props.children}</main>
      <Footer />
    </React.Fragment>
  )
}

Layout.defaultProps = {
  darkTheme: false,
}

export default Layout
